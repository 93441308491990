import axios from 'axios'
import { PrivateAxios } from './PrivateAxios'

export const postAuth = async (body) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_BACKEND_IP}/api/v1/auth/login`,
      body,
      {
        withCredentials: true,
      }
    )
    if (resp.status === 200) {
      // console.log(resp.data?.access_token)
      localStorage.setItem('token', resp.data?.access_token)
      PrivateAxios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${resp.data.access_token}`
    } else throw new Error('Invalid username or password')
    return resp
  } catch (err) {
    throw new Error('Invalid username or password')
  }
}

export const checkAuth = async () => {
  try {
    const resp = await PrivateAxios.get(`/jwt-test`)
    if (resp.status !== 200) {
      throw new Error('Token invalid')
    }
    return resp
  } catch (err) {
    throw err?.message
  }
}

export const logoutAuth = async () => {
  try {
    const resp = await PrivateAxios.get('/api/v1/auth/logout')
    if (resp.status !== 200) {
      throw new Error('Token invalid')
    }
    localStorage.removeItem('token')
    return resp
  } catch (err) {
    throw err?.message
  }
}

export const getPermissions = async () => {
  try {
    const resp = await PrivateAxios.get(`/api/v1/user/roles/`)
    if (resp.status !== 200) {
      throw new Error('Token invalid')
    }
    return resp
  } catch (err) {
    throw err
  }
}

export const isCustomer = async () => {
  try {
    const resp = await PrivateAxios.get('/api/v1/clients/details')
    if (resp.status !== 200) {
      throw new Error('You arent a Customer')
    }
    return resp
  } catch (err) {
    console.log(err)
    throw err?.message
  }
}
