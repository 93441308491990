import React, { useEffect, useState, Suspense } from 'react'

import PrivateRoutes from './PrivateRoutes'

import { DefaultRoutes } from '../constants/routes/routes'
import { Navigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login, logout, setPermissions } from '../actions/authActions'
import { getPermissions } from '../api/auth/Auth'
import { useQuery } from 'react-query'
import LoadingPage from '../pages/LoadingPage'
import { formatUsername, getTokenBody } from '../utils/utils'
import { isAuthenticated as setAuth } from '../actions/authActions'

function AuthGuard() {
  const { isAuthenticated, permissions } = useSelector((state) => state.auth)
  const location = useLocation()

  const [queryEnd, setQueryEnd] = useState(false)
  const dispatch = useDispatch()

  const { isLoading: permissionsIsLoading, refetch } = useQuery(
    ['Get-Permissions'],
    getPermissions,
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      retry: 0,
      cacheTime: 0,
      enabled: false,
      onSuccess: (data) => {
        const { sub, role } = getTokenBody()
        const greet = formatUsername(sub)
        dispatch(login({ username: greet, role }))
        dispatch(setAuth())

        dispatch(setPermissions(data?.data))
      },
      onError: () => {
        dispatch(logout())
        localStorage.removeItem('token')
      },
      onSettled: () => {
        setQueryEnd(true)
      },
    }
  )

  useEffect(() => {
    if (permissions === null) {
      setQueryEnd(false)
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions])

  if (permissionsIsLoading && !queryEnd) return <LoadingPage />

  if (!isAuthenticated && queryEnd) {
    return (
      <Navigate
        to={`../${DefaultRoutes.public.LOGIN}`}
        replace
        state={{ from: location }}
      />
    )
  }

  return (
    isAuthenticated &&
    queryEnd && (
      <Suspense fallback={<LoadingPage />}>
        <PrivateRoutes />
      </Suspense>
    )
  )
}

export default AuthGuard
