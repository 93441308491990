import { types } from '../constants/types/types'

export const login = (item) => ({
  type: types.login,
  payload: item,
})

export const logout = () => ({
  type: types.logout,
})

export const isAuthenticated = () => ({
  type: types.isAuth,
})

export const noAuthenticated = () => ({
  type: types.noAuth,
})

export const retryRefreshToken = () => ({
  type: types.retryRefreshToken,
})

export const resetRefreshToken = () => ({
  type: types.resetRefreshToken,
})

export const setPermissions = (item) => ({
  type: types.setPermissions,
  payload: item,
})
