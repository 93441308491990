import { lazy } from 'react'

const AnalystSidebar = lazy(() =>
  import('../../components/shared/sidebar/AnalystSidebar')
)
const CustomerSideBar = lazy(() =>
  import('../../components/shared/sidebar/CustomerSidebar')
)

export const DynamicComponents = {
  ANALYST_SIDEBAR: <AnalystSidebar />,
  SUPERADMIN_SIDEBAR: <AnalystSidebar />,
  CUSTOMER_SIDEBAR: <CustomerSideBar />,
}
