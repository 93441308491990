import React, { lazy } from 'react'
import { DefaultRoutes } from '../constants/routes/routes'
import { Route, Routes } from 'react-router-dom'

import MainPage from '../pages/MainPage'
import { useSelector } from 'react-redux'

const NotFound = lazy(() => import('../pages/NotFound'))

const CustomerRouter = lazy(() => import('./router/CustomerRouter'))
const SuperadminRouter = lazy(() => import('./router/SuperAdminRouter'))

function PrivateRoutes() {
  const { role } = useSelector((state) => state.auth)

  return (
    <Routes>
      {['superadmin', 'analyst'].includes(role?.toLowerCase()) && (
        <Route
          path={`${DefaultRoutes.public.DEFAULT}${DefaultRoutes.role.ANALYST}`}
          element={<MainPage />}
        >
          <Route
            path={DefaultRoutes.any}
            element={<SuperadminRouter />}
            shouldRevalidate={true}
          />
        </Route>
      )}
      {['customer'].includes(role?.toLowerCase()) && (
        <Route
          path={`${DefaultRoutes.public.DEFAULT}${DefaultRoutes.role.TRIAL}`}
          element={<MainPage />}
        >
          <Route
            path={DefaultRoutes.any}
            element={<CustomerRouter />}
            shouldRevalidate={true}
          />
        </Route>
      )}
      {/* NOT FOUND */}
      <Route path={DefaultRoutes.any} element={<NotFound />} />
    </Routes>
  )
}

export default PrivateRoutes
