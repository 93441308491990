//  {
//     uid: '234rASDFDGDSA',
//     name:'Aksel'
//  }

import { types } from '../constants/types/types'

const initState = {
  displayName: 'Batuta Scanner',
  role: 'LOADING',
  isAuthenticated: false,
  hasTryRefreshToken: false,

  permissions: null,
}

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        username: action.payload.username,
        role: action.payload.role,
      }
    case types.logout:
      return {
        permissions: null,
      }
    case types.isAuth:
      return {
        ...state,
        isAuthenticated: true,
      }
    case types.noAuth:
      return {
        ...state,
        isAuthenticated: false,
      }

    case types.retryRefreshToken:
      return {
        ...state,
        hasTryRefreshToken: true,
      }

    case types.resetRefreshToken:
      return {
        ...state,
        hasTryRefreshToken: false,
      }

    case types.setPermissions:
      return {
        ...state,
        permissions: action.payload,
      }

    default:
      return state
  }
}
