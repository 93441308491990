import React, { useEffect } from 'react'
import LoadingPage from './LoadingPage'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { getPermissions } from '../api/auth/Auth'
import { formatUsername, getTokenBody } from '../utils/utils'
import { login, logout } from '../actions/authActions'
import { isAuthenticated as setAuth } from '../actions/authActions'
import { DefaultRoutes } from '../constants/routes/routes'

const RolesLoader = () => {
  const { role } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useQuery(['Get-Permissions'], getPermissions, {
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    retry: 0,
    onSuccess: () => {
      const { sub, role } = getTokenBody()
      const greet = formatUsername(sub)
      dispatch(login({ username: greet, role }))
      dispatch(setAuth())
    },
    onError: () => {
      dispatch(logout())
      localStorage.removeItem('token')
    },
  })

  useEffect(() => {
    if (['superadmin', 'analyst'].includes(role?.toLowerCase()))
      navigate(`${DefaultRoutes.private.DEFAULT}${DefaultRoutes.role.ANALYST}`)
    else if (['customer'].includes(role?.toLowerCase()))
      navigate(`${DefaultRoutes.private.DEFAULT}${DefaultRoutes.role.TRIAL}`)
    else navigate(DefaultRoutes.public.LOGIN)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role])

  return <LoadingPage />
}

export default RolesLoader
