import { types } from '../constants/types/types'

export const showSidebar = () => ({ type: types.openSidebar })
export const hideSidebar = () => ({ type: types.closeSidebar })

export const resetForm = () => ({ type: types.validForm })
export const invalidForm = () => {
  return { type: types.invalidForm }
}
export const validForm = () => {
  return { type: types.validForm }
}

export const isLoading = () => ({ type: types.loading })
export const isLoaded = () => ({ type: types.loaded })

export const cleanRow = () => ({ type: types.cleanRow })
export const selectedRow = (item) => ({
  type: types.selectedRow,
  payload: item,
})

export const cleanPhishingCampaign = () => ({
  type: types.cleanPhishingCampaign,
})
export const selectedPhishingCampaign = (item) => ({
  type: types.selectedPhishingCampaign,
  payload: item,
})

export const cleanPhishingSenderDetails = () => ({
  type: types.cleanPhishingSenderDetails,
})
export const selectedPhishingSenderDetails = (item) => ({
  type: types.selectedPhishingSenderDetails,
  payload: item,
})

export const cleanCampaignRow = () => ({ type: types.cleanCampaignRow })
export const CampaignselectedRow = (item) => ({
  type: types.selectedCampaign,
  payload: item,
})

export const cleanCampaignControlSource = () => ({
  type: types.cleanCampaignControlSourceRow,
})
export const selectedCampaigndControlSource = (item) => ({
  type: types.selectedCampaignControlSource,
  payload: item,
})

export const cleanCampaignScenario = () => ({
  type: types.cleanCampaignScenario,
})
export const campaignScenarioSelected = (item) => ({
  type: types.selectedCampaignScenario,
  payload: item,
})

export const cleanCampaignReport = () => ({
  type: types.cleanCampaignReport,
})
export const campaignReportSelected = (item) => ({
  type: types.selectedCampaignReport,
  payload: item,
})

export const cleanCampaignRecord = () => ({
  type: types.cleanCampaignRecord,
})
export const campaignRecordSelected = (item) => ({
  type: types.selectedCampaignRecord,
  payload: item,
})

export const cleanRecordView = () => ({
  type: types.cleanRecordView,
})
export const selectedViewRecord = (item) => ({
  type: types.selectedRecordView,
  payload: item,
})

export const cleanScenarioRow = () => ({ type: types.cleanScenarioRow })
export const ScenarioselectedRow = (item) => ({
  type: types.selectedScenario,
  payload: item,
})

export const cleanMachine = () => ({
  type: types.cleanMachine,
})
export const selectMachine = (item) => ({
  type: types.selectedMachine,
  payload: item,
})

// * * TESING
export const cleanScenarioTester = () => ({
  type: types.cleanScenarioTester,
})
export const selectScenarioTester = (item) => ({
  type: types.selectedScenarioTester,
  payload: item,
})

//* EXECUTION
export const cleanPhishingExecution = () => ({
  type: types.cleanPhishingExecution,
})
export const selectedPhishingExecution = (item) => ({
  type: types.selectedPhishingExecution,
  payload: item,
})

export const cleanJob = () => ({
  type: types.cleanJob,
})
export const cleanMultiJobs = () => ({
  type: types.cleanMultiJobs,
})

export const selectedJob = (item) => ({
  type: types.selectedJob,
  payload: item,
})
export const selectedMultiJob = (item) => ({
  type: types.selectedMultiJobs,
  payload: item,
})

// * * CATALOG
export const cleanUser = () => ({ type: types.cleanUser })
export const selectedUser = (item) => ({
  type: types.selectedUser,
  payload: item,
})

export const cleanClient = () => ({ type: types.cleanClient })
export const selectedClient = (item) => ({
  type: types.selectedClient,
  payload: item,
})

export const cleanControlType = () => ({ type: types.cleanControlType })
export const selectedControlType = (item) => ({
  type: types.selectedControlType,
  payload: item,
})

export const cleanIOA = () => ({ type: types.cleanIOA })
export const selectedIOA = (item) => ({
  type: types.selectedIOA,
  payload: item,
})

export const cleanIOC = () => ({ type: types.cleanIOC })
export const selectedIOC = (item) => ({
  type: types.selectedIOC,
  payload: item,
})

export const cleanProcedure = () => ({ type: types.cleanProcedure })
export const selectedProcedure = (item) => ({
  type: types.selectedProcedure,
  payload: item,
})

export const cleanIndustry = () => ({ type: types.cleanIndustry })
export const selectedIndustry = (item) => ({
  type: types.selectedIndustry,
  payload: item,
})

export const cleanMittreTechnique = () => ({ type: types.cleanMittreTechnique })
export const selectedMittreTechnique = (item) => ({
  type: types.selectedMittreTechnique,
  payload: item,
})

export const cleanTemplate = () => ({ type: types.cleanTemplate })
export const selectedTemplate = (item) => ({
  type: types.selectedTemplate,
  payload: item,
})
