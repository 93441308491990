import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { DefaultRoutes } from '../constants/routes/routes'

import PublicGuard from './PublicGuard'
import AuthGuard from './AuthGuard'
import RolesLoader from '../pages/RolesLoader'

function MainRouter() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Route */}
        <Route path={DefaultRoutes.public.DEFAULT} element={<RolesLoader />} />

        <Route path={DefaultRoutes.public.LOGIN} element={<PublicGuard />} />

        {/* Private Routes */}
        <Route
          path={`${DefaultRoutes.private.DEFAULT}${DefaultRoutes.public.DEFAULT}${DefaultRoutes.any}`}
          element={<AuthGuard />}
          shouldRevalidate={true}
        />
        <Route
          path={DefaultRoutes.any}
          element={<Navigate to={`..${DefaultRoutes.public.DEFAULT}`} />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default MainRouter
