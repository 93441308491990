import React from 'react'

// import Avatar from '../assets/icons/Analyst2.png'
// import Avatar from '../assets/icons/avatar.gif'
// import Avatar from '../assets/icons/User.svg'
import ZeroAPT from '../assets/Ocelot/ZeroAPT-GreenWhite.svg'
import PowerByOcelot from '../assets/Ocelot/Poweredby-OCELOT-Dark.svg'
import { DynamicComponents } from '../constants/variables/DynamicComponent'
import { useSelector } from 'react-redux'

function Sidebar() {
  const { role } = useSelector((state) => state.auth)

  const CurrenSidebar = {
    ANALYST: DynamicComponents.ANALYST_SIDEBAR,
    SUPERADMIN: DynamicComponents.SUPERADMIN_SIDEBAR,
    CUSTOMER: DynamicComponents.CUSTOMER_SIDEBAR,
  }

  return (
    <div className="flex flex-col justify-between p-4 absolute w-full h-screen bg-darkmode  z-40 md:bg-inherit md:relative md:w-1/4 lg:W-1/5 ">
      <div className="flex flex-row items-center justify-center  mb-12  mt-10 md:mt-2  md:pt-4 min-h-[3.5rem]">
        <img
          className="mr-4 md:mr-4"
          src={ZeroAPT}
          loading="lazy"
          alt="ZeroAPT"
        />
      </div>
      <div className="sidebar flex flex-col h-full gap-2 overflow-auto">
        {CurrenSidebar[role]}
      </div>
      <div className="font-neue-machina group w-full text-xs md:text-sm py-2  m-auto ">
        <img
          loading="lazy"
          src={PowerByOcelot}
          alt="Power by OCELOT Team"
          className="h-16 mx-auto"
        />
      </div>
    </div>
  )
}

export default Sidebar
